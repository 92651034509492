<template>
	<div id="calendar-page">
		<router-view></router-view>
	</div>
</template>

<script>
import router from '../router';
import CMS from '../service/cms/service';
import TokenService from '../service/token-service';

export default {
	name: 'CalendarPage',
	watch: {
        "$route.params.calendarUid": {
            handler: function (newCalendarUid, oldCalendarUid) {
                let storeCalendarUid = this.$store.getters.calendarUid
                let tableHeader = this.$store.getters.tableHeader
                let isCalendarPage = this.$route.name == "calendar"

                console.log('newCalendarUid :>> ', newCalendarUid);
                console.log('oldCalendarUid :>> ', oldCalendarUid);
                console.log('storeCalendarUid :>> ', storeCalendarUid);

                let setupCalendar = () => {
                    this.setupCalendarInfo().then(() => {
                        if (tableHeader && isCalendarPage)
                            tableHeader.updateSlots()
                    })
                }
                
                if (storeCalendarUid == "") {
                    setupCalendar()
                } else if (!oldCalendarUid || newCalendarUid == oldCalendarUid) 
                    return
                else if (newCalendarUid != storeCalendarUid) {
                    this.$store.dispatch("clear-calendar");
                    setupCalendar()
                }
                else if (tableHeader && tableHeader.week.length == 0 && isCalendarPage) {
                    tableHeader.updateSlots()
                }
            },
            deep: true,
            immediate: true,
        },
	},
    destroyed() {        

        // Dispatch to clear calendar data if the current route is not in the child route list
        console.log("destroy calendar page");
        let currentRouteName = this.$route.name
        let routes = router.options.routes  
        let calendarLayout = routes.find((route) => route.name == "calendar-layout")
        if (!calendarLayout) return

        if (!calendarLayout.children.find((route) => route.name == currentRouteName)){
            this.$store.dispatch("clear-calendar");
        }
    },
	methods: {
		setupCalendarInfo(){
			let calendarUid = this.$route.params.calendarUid
			let isAuthorized = TokenService.tokenIsExists()

			return CMS.calendar
                .getByUid(calendarUid, isAuthorized)
                .then((data) => {
                    if (data.err) 
                        throw data.err

                    let calendarData = data;
                    // Save info to storage
                    this.$store.dispatch("set-calendar", {
                        uid: calendarUid,
                        calendar: calendarData,
                    });
                    this.$store.dispatch("set-last-calendar", calendarData);
                    if (calendarData.restricted)
                        return;
                    this.$store.dispatch("setup-slot-templates")
					this.setupWorkdayCalendar(data.workdayCalendarId)

                    // Setup work hours for the calendar
                    this.setupWorkHours({
                        start: data.from,
                        end: data.to,
                    })

                    // Set last calendar for short-term and long-term use\
                    if (this.$route.name == 'calendar') {
                        this.$store.dispatch("set-calendar-props", {
                            selectedDate: this.$route.query.dt,
                            columnCount: Number(this.$route.query.w),
                        });
                    }
                })
                .catch((error) => {
                    let isHttpError = error.response !== undefined;
                    if (!isHttpError) {
                        return console.error(error);
                    }

                    let status = error.response.status;
                    switch (status) {
                        case 400:
                            this.$store.dispatch('set-not-found', true)
                            break;
                        case 401: 
                            if (!TokenService.tokenIsExists()){
                                this.showUnauthorizedMessage()
                            }
                            break;
                        
                        case 403:
                            if (this.$route.params.isLastCalendar) {
                                let calendarUid = this.$store.state.user.calendars[0].uid
                                router.push({
                                    name: "calendar",
                                    params: {
                                        calendarUid: calendarUid
                                    },
                                });
                            }
                            break;

                        case 404:
                            this.$store.dispatch('set-not-found', true)
                            break;
                    }
                })
                .finally(() => {
                    this.$store.dispatch("calendar-set-is-loaded")
                });
		},
		setupWorkdayCalendar(workdayCalendarId){
			CMS.geo.workdayCalendar
				.getByID(workdayCalendarId)
				.then((workdayCalendar) => {
					if (workdayCalendar.err) throw workdayCalendar.err

					let holidays = this.setupHolidays(workdayCalendar.weekends)
					let special = this.setupSpecialDays(workdayCalendar.specialDays)

					this.$store.dispatch("update-work-date", {
						holidays: holidays,
						special: special,
					});
				})
				.catch(() => {});
		},
		setupWorkHours(work){
            let start = {
                hour: Math.floor(work.start / 60),
                minute: work.start - Math.floor(work.start / 60) * 60
            }
            let end = {
                hour: Math.floor(work.end / 60),
                minute: work.end  - Math.floor(work.end / 60) * 60
            }

            this.$store.dispatch('update-work-time', {start, end})
        },

		setupSpecialDays(specialDays) {
            let special = specialDays || [];
            return special.map((day) => {
                if (day["dt"] !== undefined) {
                    let date = new Date(day["dt"]);
                    return {
                        m: date.getMonth(),
                        d: date.getDate(),
                        typeId: day.typeId,
                    };
                }

                return { ...day, m: day.m - 1 };
            });
        },

        setupHolidays(holidays) {
            return holidays.map((dayIndex) => {
                if (dayIndex == 7) return 0;
                return dayIndex;
            });
        },

		showUnauthorizedMessage(){
			let ComponentClass = Vue.extend(CalendarUnauthorizedMessage)
            let instance = new ComponentClass()
            let studio = document.getElementById('studio')
            
            instance.$mount() // pass nothing
            studio.appendChild(instance.$el)

        },
	}
};
</script>

<style scoped>
#calendar-page{
    height: inherit;
}
</style>